import type { Story } from 'types/storyblok'
import { mapValuesDeep } from 'lib/mapValuesDeep'

/**
 * Within this codebase we've been using the `cached_url` value of the Storyblok's Link.
 * This becomes an issue when you've renamed a story. The cached_url is not updated.
 *
 * Storyblok recommends fetching the story using the 'resolveLinks' parameter.
 * When doing that, it'll add the "story"-reference to the Link data.
 *
 * This function updates the cached_url with "newest" url from the reference.
 */
export function storyblokResolveLinks(story: Story): Story {
  return mapValuesDeep(story, (value, key, obj) => {
    if (key === 'cached_url' && obj?.story?.full_slug)
      return obj.story.full_slug
    return value
  })
}
