import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import mapValues from 'lodash/mapValues'

export function mapValuesDeep(
  input: unknown,
  cb: (value: unknown, objKey?: string, obj?: Record<string, any>) => void,
  objKey?: string,
  obj?: Record<string, any>,
): any {
  if (isArray(input))
    return input.map(inputItem => mapValuesDeep(inputItem, cb))
  else if (isObject(input))
    return mapValues(input, (value, key, obj) => mapValuesDeep(value, cb, key, obj))

  return cb(input, objKey, obj)
}
